<template>
    <modal ref="modalAceptarAfiliacion" titulo="Aceptar afiliación" tamano="modal-lg" no-aceptar adicional="Afiliar" @adicional="aceptarSolicitud">
        <div class="row mx-0">
            <div class="col-auto px-3" />
            <div class="col">
                <div class="row mx-0 align-items-center border bg-fondo br-12 py-2 px-2" style="height:49px;">
                    <img :src="cliente_foto" class="obj-cover rounded-circle border" width="32" height="32" />
                    <div class="col-auto text-general f-17 f-600">
                        {{ cliente }}
                    </div>
                    <div class="col-auto text-general f-17 f-600">
                        Código: {{ idSolicitud }}
                    </div>
                </div>
                <div class="row mx-0 py-3" />
                <div class="row mx-0">
                    <div class="col">
                        <p class="text-general f-12 pl-3">
                            Documento
                        </p>
                        <el-input v-model="usuario.documento" class="w-100" size="small" />
                    </div>
                    <div class="col">
                        <p class="text-general f-12 pl-3">
                            Cupon de credito
                        </p>
                        <money v-if="!usuario.cupoIlimitado" v-model="usuario.cupo" class="input-money w-100" placeholder="Valor" size="small" v-bind="money" />
                        <el-input v-else class="w-100" size="small" placeholder="Valor" disabled />
                        <el-checkbox v-model="usuario.cupoIlimitado" class="mt-2 check-dark ml-4" @change="cambioCupo($event)">
                            Ilimitado
                        </el-checkbox>
                    </div>
                    <div class="col">
                        <p class="text-general f-12 pl-3">
                            Salario
                        </p>
                        <money v-model="usuario.salario" class="input-money w-100" placeholder="Valor" size="small" v-bind="money" />
                    </div>
                </div>
                <div class="row mx-0 py-4" />
                <div class="row mx-0">
                    <div v-if="adjunto" class="col-12 mt-3">
                        <div class="row mx-0">
                            <div class="card-file d-middle">
                                <div class="h-100 bg-gris d-middle-center" style="width:48px;border-radius:12px 0px 0px 12px;">
                                    <i class="icon-file-pdf f-17" />
                                </div>
                                <div class="col f-12 text-general tres-puntos">
                                    documento adjunto
                                </div>
                            </div>
                            <div class="btn-download-file cr-pointer ml-2" @click="descargar">
                                <i class="icon-dowload-arrow f-20" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-auto px-3" />
        </div>
    </modal>
</template>

<script>
import {Money} from 'v-money'
export default {
    components:{Money},
    data(){
        return {
            idSolicitud: '',
            cliente: '',
            cliente_foto: '',
            usuario: {
                documento: '',
                cupo: '',
                salario: '',
                cupoIlimitado: 0,
            },
            money: {
                thousands: '.',
                prefix: '$ ',
                precision: 0,
                masked: false
            },
            cuponClienteCopia: 0,
            adjunto: null,
        }
    },
    methods: {
        toggle(data){
            this.idSolicitud = data.id;
            this.cliente = data.cliente;
            this.cliente_foto = data.cliente_foto;
            this.usuario.documento = data.documento;
            this.usuario.cupo = data.cupo;
            this.usuario.salario = data.salario;
            this.usuario.cupoIlimitado = data.cupoIlimitado;
            this.cuponClienteCopia = data.cupo;
            this.adjunto = data.adjunto;
            this.$refs.modalAceptarAfiliacion.toggle();
        },
        cambioCupo(event){
            if (event){
                this.usuario.cupo = '';
            } else {
                this.usuario.cupo = this.cuponClienteCopia;
            }
        },
        aceptarSolicitud(){
            this.usuario.id = this.idSolicitud;
            this.$emit("aceptado", this.usuario);
        },
        descargar(){
            if(this.adjunto === null || this.adjunto === '')return
            window.open(this.adjunto)
        },
    }
}
</script>
<style lang="scss" scoped>
.btn-file{
    width: 302px;
    height: 50px;
    background-color: #F6F9FB;
    border: 1px dashed #637381;
    display: flex;
    align-items: center;
    justify-content: center;
}

.card-file{
    width: 312px;
    height: 60px;
    border-radius: 12px;
    box-shadow: 0px 2px 4px #00000014;
}

.btn-delete-file{
    width: 60px;
    height: 60px;
    border-radius: 12px;
    background-color: #FF5158;
    color: #FFFFFF;
    display: flex;
    align-items: center;
    justify-content: center;
}

.btn-download-file{
    width: 60px;
    height: 60px;
    border-radius: 12px;
    background-color: #29D884;
    color: #FFFFFF;
    display: flex;
    align-items: center;
    justify-content: center;
}
</style>
